.mat-dialog-override.cdk-overlay-pane.mat-mdc-dialog-panel {
  max-width: none;
  min-width: var(--mat-dialog-container-min-width, 0);
}

.mat-dialog-override {

  mat-dialog-container {
    > :first-child {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    mat-dialog-content,
    div[mat-dialog-content] {
      flex-grow: 1;
      max-height: unset;
    }
  }
}

.mat-dialog-override .mat-dialog-container {
  @media (max-width: 576px) {
    padding: 0;
    overflow: hidden;
  }
}

.mat-dialog-override .mat-dialog-content {
  @media (max-width: 576px) {
    margin: 0;
  }
}

.mat-dialog-fullscreen .mat-dialog-container {
  padding: 0;
  overflow: hidden;

  > :first-child {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.mat-dialog-fullscreen .mat-dialog-content {
  margin: 0;
  flex-grow: 1;
  max-height: unset;
}