@use '@angular/material' as mat;
@use 'ngx-toastr/toastr';

.toast-container {
  .ngx-toastr {
    min-height: 3rem;
    padding: 0.875rem 1rem;
    color: #fff;
    background-color: #333;
    background-position: 1rem center;
    background-size: 1rem;
    border-radius: 0.5rem;

    @include mat.elevation(4);

    &:hover {
      @include mat.elevation(8);
    }
  }

  .toast-success,
  .toast-info,
  .toast-warning,
  .toast-error {
    padding-left: 3rem;
    color: #fff;
  }

  .toast-info {
    background-color: var(--toast-info-background-color);
  }

  .toast-success {
    background-color: var(--toast-success-background-color);
  }

  .toast-warning {
    background-color: var(--toast-warning-background-color);
  }

  .toast-error {
    background-color: var(--toast-error-background-color);
  }

  .toast-close-button {
    top: -0.5rem;
    right: -0.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    font-size: 1rem;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .25);

    &:hover {
      color: inherit;
      opacity: .6;
    }
  }
}
