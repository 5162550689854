@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;
@use 'app-theme';

@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

//
// Light theme
//
$light-config: (
  color: (
    theme-type: 'light',
    primary: mat.$azure-palette,
    tertiary: mat.$blue-palette,
  ),
);

$light-theme: mat.private-deep-merge-all(
  mat.define-theme($light-config),
  mtx.define-theme($light-config)
);

html {
  @include mat.all-component-themes($light-theme);
  @include mtx.all-component-themes($light-theme);
  @include app-theme.theme($light-theme);

  @include mat.color-variants-backwards-compatibility($light-theme);
  @include mtx.color-variants-backwards-compatibility($light-theme);
}

//
// Dark theme
//
$dark-config: (
  color: (
    theme-type: 'dark',
    primary: mat.$cyan-palette,
    tertiary: mat.$orange-palette,
  ),
);

$dark-theme: mat.private-deep-merge-all(
  mat.define-theme($dark-config),
  mtx.define-theme($dark-config)
);

.theme-dark {
  color-scheme: dark;

  @include mat.all-component-colors($dark-theme);
  @include mtx.all-component-colors($dark-theme);
  @include app-theme.theme($dark-theme);

  @include mat.color-variants-backwards-compatibility($dark-theme);
  @include mtx.color-variants-backwards-compatibility($dark-theme);
}
